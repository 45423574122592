import React from "react";

const EmptyHeader = (props) => {

	return (
		<>
			<header>
				{/* Header code */}
			</header>
		</>
	);
};

export default EmptyHeader;
