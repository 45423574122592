import {
    FETCH_ALL_TRANSACTION_START,
    FETCH_ALL_TRANSACTION_SUCCESS,
    FETCH_ALL_TRANSACTION_FAILURE,
    FETCH_MORE_ALL_TRANSACTION_START,
} from "./ActionConstant";

// Get all transaction actions.

export function fetchAllTransactionStart(data) {
    return {
        type: FETCH_ALL_TRANSACTION_START,
        data,
    };
}

export function fetchAllTransactionSuccess(data) {
    return {
        type: FETCH_ALL_TRANSACTION_SUCCESS,
        data,
    };
}

export function fetchAllTransactionFailure(error) {
    return {
        type: FETCH_ALL_TRANSACTION_FAILURE,
        error,
    };
}

export function fetchMoreAllTransactionStart(data) {
    return {
        type: FETCH_MORE_ALL_TRANSACTION_START,
        data,
    };
}
