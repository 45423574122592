import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import {
    FETCH_ALL_TRANSACTION_START,
    FETCH_MORE_ALL_TRANSACTION_START,
} from "../actions/ActionConstant";

import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/Helper/ToastNotification";
import {
    fetchAllTransactionSuccess,
    fetchAllTransactionFailure,
} from "../actions/TransactionAction";

function* fetchAllTransactionAPI(action) {
    try {
        const response = yield api.postMethod("wallets_history", action.data);
        if (response.data.success) {
            yield put(fetchAllTransactionSuccess(response.data.data));
        } else {
            yield put(fetchAllTransactionFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchAllTransactionFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

export default function* TransactionSaga() {
    yield all([
        yield takeLatest(FETCH_ALL_TRANSACTION_START, fetchAllTransactionAPI),
        yield takeLatest(FETCH_MORE_ALL_TRANSACTION_START, fetchAllTransactionAPI),
    ]);
}
