import React from "react";

const EmptyFooter = () => {
	return (
		<>
			<footer>
				{/* Footer code */}
			</footer>
		</>
	);
};

export default EmptyFooter;
