import React, { useCallback } from "react";
import { Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Seller.css";
import { withTranslation, useTranslation } from "react-multi-lang";

const ApprovedDocumentIndex = (props) => {
  const t = useTranslation();
  return (
    <>
      <div className="import-rules-sec">
        <div className="become-a-seller-header-sec">
          <div className="become-a-seller-steps-badge">{t("3_4 Completed")}</div>
        </div>
        <div className="become-a-seller-body-sec">
          <div className="become-a-seller-step-count-sec">
            <div className="become-a-seller-step-count-bg">3</div>
            <span>{t("the_submitted_documents_are_approved")}</span>
          </div>
          <div className="awaiting-for-approval-box">
            <div className="awaiting-for-approval-img-sec">
              <Image
                src={
                  window.location.origin +
                  "/images/content-creator/approved-document-img.svg"
                }
                className="awaiting-for-approval-img"
              />
            </div>
            <div className="awaiting-for-approval-info">
              <p>{t("your_documents_are_approved")}</p>
            </div>
          </div>
          <div className="become-a-seller-btn-sec">
            <Button
              className="default-blue-btn"
              onClick={() => props.setStep(5)}
            >
              {t("continue")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedDocumentIndex;
