import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
  fetchSingleUserProfileFailure,
  fetchSingleUserProfileSuccess,
} from "../actions/OtherUserAction";
import { FETCH_SINGLE_USER_PROFILE_START } from "../actions/ActionConstant";

function* fetchOtherUserProfileAPI() {
  try {
    const inputData = yield select(
      (state) => state.otherUser.userDetails.inputData
    );
    if (inputData.user_unique_id === localStorage.getItem("user_unique_id")) {
      window.location.assign("/profile");
    }
    const response = yield api.postMethod("other_profile", inputData);
    if (response.data.success) {
      yield put(fetchSingleUserProfileSuccess(response.data.data));
    } else {
      yield put(fetchSingleUserProfileFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
      window.location.assign("/");
    }
  } catch (error) {
    yield put(fetchSingleUserProfileFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield call(getErrorNotificationMessage, error.message);  
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_SINGLE_USER_PROFILE_START, fetchOtherUserProfileAPI),
  ]);
}
