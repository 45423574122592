import React, { useState, useEffect } from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AccountCertificationIndex from "./AccountCertificationIndex";
import ApprovedDocumentIndex from "./ApprovedDocumentIndex";
import AwaitingForDocumentApprovalIndex from "./AwaitingForDocumentApprovalIndex";
import ImportantRulesIndex from "./ImportantRulesIndex";
import "./Seller.css";
import SellerAddBankIndex from "./SellerAddBankIndex";
import UpdateBillingInformationIndex from "./UpdateBillingInformationIndex";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";

const BecomeASellerIndex = (props) => {

  const [step, setStep] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(fetchUserDetailsStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !props.profile.loading && Object.keys(props.profile.data).length > 0) {
      if (props.profile.data.is_content_creator === 2) {
        navigate("/");
      }
    }
    setSkipRender(false);
    if (props.profile.data.content_creator_step === 0) {
      setStep(1);
    } else if (props.profile.data.content_creator_step === 1) {
      setStep(2);
    }
    else if (props.profile.data.content_creator_step === 2) {
      setStep(3);
    }
    else if (props.profile.data.content_creator_step === 3) {
      setStep(4);
    }
    else {
      setStep("");
    }
  }, [props.profile]);

  return (
    <>
      <div className="become-a-seller-sec">
        <Container>
          <Row>
            <Col md={6}>
              <div className="become-a-seller-left-sec">
                <div className="become-a-seller-info">
                  <h1>{t("business_born_from")} <span>{t("passions")}</span></h1>
                  <p>{t("go_live_para")}</p>
                </div>
                <div className="become-a-seller-img-sec">
                  <Image
                    src={window.location.origin + "/images/content-creator/whynot-content-creator.png"}
                    className="become-a-seller-img"
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="become-a-seller-steps-sec">
                {step === 1 && (
                  <ImportantRulesIndex setStep={setStep} />
                )}
                {step === 2 && (
                  <AccountCertificationIndex setStep={setStep} />
                )}
                {step === 3 && (
                  <AwaitingForDocumentApprovalIndex setStep={setStep} />
                )}
                {step === 4 && (
                  <ApprovedDocumentIndex setStep={setStep} />
                )}
                {step === 5 && (
                  <SellerAddBankIndex setStep={setStep} />
                )}
                {step === 6 && (
                  <UpdateBillingInformationIndex setStep={setStep} />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.users.profile,
  becomeSeller: state.users.becomeSeller,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(BecomeASellerIndex));

