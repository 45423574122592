import React from 'react'
import Skeleton from "react-loading-skeleton";

const TableOrderLoader = () => {
  return (
    <div className="order-list-table">
      <Skeleton height={80} count ={4}/>
    </div>
  )
}

export default TableOrderLoader;