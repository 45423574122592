import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Verification.css";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { verifyForgotPasswordStart, forgotPasswordStart } from "../../store/actions/UserAction";


const VerifyForgotPassword = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    }
  }, []);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const submitVerfication = (event) => {
    event.preventDefault();
    if (otp.length === 6) {
      props.dispatch(
        verifyForgotPasswordStart({
          email: email,
          verification_code: otp,
        }));
    }
  };
  useEffect(() => {
    if (!skipRender &&
      !props.verifyForgotPassword.loading &&
      Object.keys(props.verifyForgotPassword.data).length > 0) {
      navigate("/reset-password")
    }
    setSkipRender(false);
  }, [props.verifyForgotPassword]);

  const resendVerfication = () => {
    props.dispatch(forgotPasswordStart({
      email: email,
    }));
  };

  return (
    <>
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={5}>
              <Link to="/login">
                <div className="verification-logo-sec">
                  <Image
                    className="verification-logo"
                    src={
                      window.location.origin + "/images/logo-black.png"
                    }
                  />
                </div>
              </Link>
              <div className="verification-box">
                <h4>
                  {t("verify_forgot_password_para")}
                </h4>
                <p>{t("we_emailed_verification_to")}  {email}
                </p>
                <div className="verification-item">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                  />
                </div>
                <div className="verification-btn-sec">
                  <Button
                    className="default-btn"
                    onClick={submitVerfication}
                    disabled={otp.length < 6 && props.verifyForgotPassword.buttonDisable}
                  >
                    {props.verifyForgotPassword.loadingButtonContent ?
                      props.verifyForgotPassword.loadingButtonContent :
                      t("validate")
                    }
                  </Button>
                  <Button
                    className="default-outline-btn"
                    onClick={resendVerfication}
                    disabled={props.forgotPassword.buttonDisable}
                  >
                    {props.forgotPassword.loadingButtonContent != null ?
                      props.forgotPassword.loadingButtonContent :
                      t("resend")
                    }
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  verifyForgotPassword: state.users.verifyForgotPassword,
  forgotPassword: state.users.forgotPasswordInputData,

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(VerifyForgotPassword));
