import React, { useState, useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Seller.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { becomeSellerStart } from "../../store/actions/UserAction";

const ImportantRulesIndex = (props) => {

  const t = useTranslation();
  const [userUniqueId, setuserUniqueId] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    setuserUniqueId(props.profile.data.user_unique_id);
  }, []);

  useEffect(() => {
    if (!skipRender && !props.becomeSeller.loading && Object.keys(props.becomeSeller.data).length > 0) {
      props.setStep(2);
    }
    setSkipRender(false)
  }, [props.becomeSeller]);

  const handleSubmit = () => {
    props.dispatch(becomeSellerStart({
      user_unique_id: userUniqueId,
      step: 1,
    }));
  }

  return (
    <>
      <div className="import-rules-sec">
        <div className="become-a-seller-header-sec">
          <div className="become-a-seller-steps-badge">
            {t("1_4_completed")}
          </div>
        </div>
        <div className="become-a-seller-body-sec">
          <div className="become-a-seller-step-count-sec">
            <div className="become-a-seller-step-count-bg">
              1
            </div>
            <span>{t("the_important_rules")}</span>
          </div>
          <div className="import-rules-list-sec">
            <ul className="list-unstyled import-rules-list">
              <li>
                {t("the_important_rules_para1")}
              </li>
              <li>
                {t("the_important_rules_para2")}
              </li>
              <li>
                {t("the_important_rules_para3")}
              </li>
              <li>
                {t("the_important_rules_para4")}
              </li>
              <li>
                {t("the_important_rules_para5")}
              </li>
              <li>
                {t("the_important_rules_para6")}
              </li>
            </ul>
          </div>
          <div className="become-a-seller-btn-sec">
            <Button className="default-blue-btn"
              onClick={handleSubmit}
            >
              {props.becomeSeller.loadingButtonContent !== null
                ? props.becomeSeller.loadingButtonContent :
                t("i_agree_to_the_rules")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  becomeSeller: state.users.becomeSeller,
  profile: state.users.profile
})
function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ImportantRulesIndex));
