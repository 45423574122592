import React from 'react';
import { Container } from 'react-bootstrap';

const Page404 = () => {
    return (
        <>
        <Container>
            <div className="not-found-page mt-4">
                <img src={window.location.origin + "/images/404-new.png"} width="300" />
            </div>
            </Container>
        </>
    )
}

export default Page404;