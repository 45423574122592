import React, { useEffect } from "react";
import "./CategorySidebar.css";
import { Accordion, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import StaticSidebar from "../static/StaticSidebar";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchCategoriesListStart } from "../../store/actions/HomePageAction";
import Skeleton from "react-loading-skeleton";

const CategorySidebar = (props) => {

  const t = useTranslation();
  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  return (

    props.categoriesList.loading ?
      <>
        <Skeleton count={1} height={70} />
        <Skeleton count={3} height={70} />
      </>
      :
      props.categoriesList.data.categories &&
        props.categoriesList.data.categories.length > 0 ?
        <div className="home-page-left-sec">
          <div className="left-side-title">
            <h3>{t("categories")}</h3>
          </div>
          <div className="category-list-accordion">
            {props.categoriesList.data.categories.map(
              (category, i) => (
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>{category.name}</Accordion.Header>
                    <Accordion.Body>
                      <div className="category-list">
                        {category.sub_categories.map(
                          (subCategory, j) => (
                            <ul className="list-unstyled m-0" key={j}>
                              <li>
                                <Link to={`/category/${subCategory.category_id}/${subCategory.unique_id}`}>{subCategory.name} </Link>
                              </li>
                            </ul>
                          ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
          </div>
          <StaticSidebar />
        </div>
        :
        null
  );
};

const mapStateToProps = (state) => ({
  categoriesList: state.homepage.categoriesList,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(CategorySidebar));
