import {
  FETCH_WALLET_DETAILS_START,
  FETCH_WALLET_DETAILS_SUCCESS,
  FETCH_WALLET_DETAILS_FAILURE,
  ADD_MONEY_VIA_PAYPAL_START,
  ADD_MONEY_VIA_PAYPAL_SUCCESS,
  ADD_MONEY_VIA_PAYPAL_FAILURE,
  GENERATE_STRIPE_PAYMENT_START,
  GENERATE_STRIPE_PAYMENT_SUCCESS,
  GENERATE_STRIPE_PAYMENT_FAILURE,
  PAYMENT_BY_STRIPE_START,
  PAYMENT_BY_STRIPE_SUCCESS,
  PAYMENT_BY_STRIPE_FAILURE,
  SEND_WITHDRAW_REQUEST_START,
  SEND_WITHDRAW_REQUEST_SUCCESS,
  SEND_WITHDRAW_REQUEST_FAILURE,
  FETCH_WITHDRAWALS_START,
  FETCH_MORE_WITHDRAWALS_START,
  FETCH_WITHDRAWALS_SUCCESS,
  FETCH_WITHDRAWALS_FAILURE,
  CANCEL_WITHDRAW_REQUEST_START,
  CANCEL_WITHDRAW_REQUEST_SUCCESS,
  CANCEL_WITHDRAW_REQUEST_FAILURE,
  FETCH_PAYMENTS_START,
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENTS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  walletData: {
    data: {},
    loading: true,
    error: false,
  },
  addMoneyInput: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  generateStripe: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  paymentByStripe: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  sendWithDraw: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  withDrawals: {
    data: {},
    loading: true,
    error: false,
  },
  cancelWithDraw: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  payments: {
    data: {},
    loading: true,
    error: false,
  },
};

const WalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET_DETAILS_START:
      return {
        ...state,
        walletData: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        walletData: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_WALLET_DETAILS_FAILURE:
      return {
        ...state,
        walletData: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case ADD_MONEY_VIA_PAYPAL_START:
      return {
        ...state,
        addMoneyInput: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
        },
      };
    case ADD_MONEY_VIA_PAYPAL_SUCCESS:
      return {
        ...state,
        addMoneyInput: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADD_MONEY_VIA_PAYPAL_FAILURE:
      return {
        ...state,
        addMoneyInput: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case GENERATE_STRIPE_PAYMENT_START:
      return {
        ...state,
        generateStripe: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
        },
      };
    case GENERATE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        generateStripe: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case GENERATE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        generateStripe: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case PAYMENT_BY_STRIPE_START:
      return {
        ...state,
        paymentByStripe: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
        },
      };
    case PAYMENT_BY_STRIPE_SUCCESS:
      return {
        ...state,
        paymentByStripe: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case PAYMENT_BY_STRIPE_FAILURE:
      return {
        ...state,
        paymentByStripe: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SEND_WITHDRAW_REQUEST_START:
      return {
        ...state,
        sendWithDraw: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case SEND_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        sendWithDraw: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SEND_WITHDRAW_REQUEST_FAILURE:
      return {
        ...state,
        sendWithDraw: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_WITHDRAWALS_START:
      return {
        ...state,
        withDrawals: {
          data: {
            history: [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        withDrawals: {
          data: {
            history: [...state.withDrawals.data.history, ...action.data.history],
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_WITHDRAWALS_FAILURE:
      return {
        ...state,
        withDrawals: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_MORE_WITHDRAWALS_START:
      return state;

    case CANCEL_WITHDRAW_REQUEST_START:
      return {
        ...state,
        cancelWithDraw: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case CANCEL_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        cancelWithDraw: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CANCEL_WITHDRAW_REQUEST_FAILURE:
      return {
        ...state,
        cancelWithDraw: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_PAYMENTS_START:
      return {
        ...state,
        payments: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_PAYMENTS_FAILURE:
      return {
        ...state,
        payments: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default WalletReducer;
