import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { cancelWithDrawRequestStart } from "../../store/actions/WalletAction";
import { useTranslation, withTranslation } from "react-multi-lang";

const CancelWithdrawModal = (props) => {

  const t=useTranslation("");
  const [cancelWithdrawInputData, setCancelWithdrawInputData] = useState({});

  useEffect(() => {
    setCancelWithdrawInputData({
      ...cancelWithdrawInputData,
      user_withdrawal_id: props.data.user_withdrawal_id,
    });
  }, [props.cancelData]);

  const handleSubmit = () => {
    props.dispatch(cancelWithDrawRequestStart(cancelWithdrawInputData));
    setCancelWithdrawInputData();
    props.closeCancelWithdrawModal();
  };
  

  return (
    <>
      <Modal
        className="modal-dialog-center"
        size="md"
        centered
        show={true}
        onHide={props.closeCancelWithdrawModal}
      >
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t("cancel_withdraw_request")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="floating-form">
                <div className="floating-label">
                <Form.Control
                    className="floating-input"
                    type="textarea"
                    placeholder=""
                    value={cancelWithdrawInputData.cancel_reason}
                    name="cancel_reason"
                    onChange={(event) =>
                      setCancelWithdrawInputData({
                        ...cancelWithdrawInputData,
                        cancel_reason: event.currentTarget.value,
                      })
                    }
                  />
                  <span className="highlight"></span>
                  <label className="default-label">{t("cancel_reason")}</label>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={props.closeCancelWithdrawModal}
              >
                {t("cancel")}
              </Button>
              <Button
                type="submit"
                className="btn btn-success"
                data-dismiss="modal"
                disabled={!cancelWithdrawInputData.cancel_reason || props.cancelWithDraw.buttonDisable}
              >
                {props.cancelWithDraw.loadingButtonContent !== null
                  ? props.cancelWithDraw.loadingButtonContent
                  : t("cancel_withdraw")}
              </Button>
            </Modal.Footer>
          </Form>
      </Modal>
    </>
  )
}

const mapStateToPros = (state) => ({
  cancelWithDraw: state.wallet.cancelWithDraw,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(CancelWithdrawModal));
