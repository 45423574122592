import {
    FETCH_STATIC_PAGE_START,
    FETCH_STATIC_PAGE_SUCCESS,
    FETCH_STATIC_PAGE_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
    pageData: {
        data: {},
        loading: true,
        error: false,
    },
};

const PageReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATIC_PAGE_START:
            return {
                ...state,
                pageData: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_STATIC_PAGE_SUCCESS:
            return {
                ...state,
                pageData: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_STATIC_PAGE_FAILURE:
            return {
                ...state,
                pageData: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        default:
            return state;
    }
};

export default PageReducer;
