import React, { useState } from "react";
import EmptyFooter from "./Footer/EmptyFooter";
import EmptyHeader from "./Header/EmptyHeader";

const EmptyLayout = (props) => {

  return (
    <>
      <EmptyHeader/>
      {props.children}
      <EmptyFooter />
    </>
  );
};

export default EmptyLayout;
