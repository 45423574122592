import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { Row,Col } from 'react-bootstrap';

const KycLoader = () => {
    return (
        <>
            <Row>
                <Skeleton count={2} width={150} />
                <Col lg={6}>
                    <Skeleton count={1} width={270} height={160} />
                    <div className="upload-doc-btm-sec mt-3">
                        <Skeleton count={1} width={160} height={35} />
                    </div>
                </Col>
                <Col lg={6}>
                    <Skeleton count={1} width={270} height={160} />
                </Col>
            </Row>
            <Row>
                <Skeleton count={2} width={150} />
                <Col lg={6}>
                    <Skeleton count={1} width={270} height={160} />
                    <div className="upload-doc-btm-sec mt-3">
                        <Skeleton count={1} width={160} height={35} />
                    </div>
                </Col>
                <Col lg={6}>
                    <Skeleton count={1} width={270} height={160} />
                </Col>
            </Row>
        </>
    )
}

export default KycLoader;