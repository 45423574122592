import { call, select, put, takeLatest, all, take } from "redux-saga/effects";
import {
    addBankAccountSuccess,
    addBankAccountFailure,
    getBankAccountSuccess,
    getBankAccountFailure,
    getBankAccountStart,
    deleteBankAccountSuccess,
    deleteBankAccountFailure,
    makeDefaultBankAccountSuccess,
    makeDefaultBankAccountFailure,
    viewBankAccountSuccess,
    viewBankAccountFailure,
} from "../actions/BankAccountAction";
import {
    ADD_BANK_ACCOUNT_START,
    GET_BANK_ACCOUNT_START,
    DELETE_BANK_ACCOUNT_START,
    MAKE_DEFAULT_BANK_ACCOUNT_START,
    VIEW_BANK_ACCOUNT_START,
} from "../actions/ActionConstant";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/Helper/ToastNotification";
import api from "../../Environment";

function* addBankAccountAPI(action) {
    try {
        const response = yield api.postMethod("billing_accounts_save", action.data);

        if (response.data.success) {
            yield put(addBankAccountSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(addBankAccountFailure(response.data.error));
            yield put(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(addBankAccountFailure(error));
        yield put(addBankAccountFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* getBankAccountAPI(action) {
    try {
        const response = yield api.postMethod("user_billing_accounts_list", action.data);

        if (response.data.success) {
            yield put(getBankAccountSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(getBankAccountFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(getBankAccountFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* deleteAccountBankAccountAPI(action) {
    try {
        const response = yield api.postMethod("billing_accounts_delete", action.data);

        if (response.data.success) {
            yield put(deleteBankAccountSuccess(response.data));
            yield put(getBankAccountStart());
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(deleteBankAccountFailure(response.data.error));
            yield put(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(deleteBankAccountFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* makeDefaultBankAccountAPI(action) {
    try {
        const response = yield api.postMethod("user_billing_accounts_default", action.data);

        if (response.data.success) {
            yield put(makeDefaultBankAccountSuccess(response.data));
            yield put(getBankAccountStart());
            yield call(getSuccessNotificationMessage, response.data.message);

        } else {
            yield put(makeDefaultBankAccountFailure(response.data.error));
            yield put(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(makeDefaultBankAccountFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* viewBankAccountAPI(action) {
    try {
        const response = yield api.postMethod("user_billing_accounts_view", action.data);

        if (response.data.success) {
            yield put(viewBankAccountSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(viewBankAccountFailure(response.data.error));
            yield put(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(viewBankAccountFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

export default function* BankAccountSaga() {
    yield all([
        yield takeLatest(ADD_BANK_ACCOUNT_START, addBankAccountAPI),
        yield takeLatest(GET_BANK_ACCOUNT_START, getBankAccountAPI),
        yield takeLatest(DELETE_BANK_ACCOUNT_START, deleteAccountBankAccountAPI),
        yield takeLatest(MAKE_DEFAULT_BANK_ACCOUNT_START, makeDefaultBankAccountAPI),
        yield all([yield takeLatest(VIEW_BANK_ACCOUNT_START, viewBankAccountAPI)]),
    ])
}

