import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Seller.css";
import { useDropzone } from 'react-dropzone'
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { becomeSellerStart, getKycDocumentStart } from "../../store/actions/UserAction";
import NoDataFound from '../Helper/NoDataFound';
import KycLoader from '../Helper/KycLoader';

const AccountCertificationIndex = (props) => {

  const t = useTranslation();

  const [inputData, setInputData] = useState({});

  const [image, setImage] = useState({});

  const [uploadDocumentID, setUploadDocumentID] = useState(null);

  const [userUniqueId, setuserUniqueId] = useState("");

  const [skipRender, setSkipRender] = useState(true);

  const [activeIndex, setActiveIndex] = useState(null);


  useEffect(() => {
    props.dispatch(getKycDocumentStart());
    setuserUniqueId(props.profile.data.user_unique_id);
  }, []);

  const handleChangeImage = (event, doc) => {
    if (event.currentTarget.type === "file") {
      setInputData({
        ...inputData,
        document_file: event.currentTarget.files[0],
        document_id: doc.document_id,
        step: 2,
      });
      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      reader.onloadend = () => {
        setImage({ ...image, [doc.document_id]: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  useEffect(() => {
    if (!skipRender && !props.becomeSeller.loading && Object.keys(props.becomeSeller.data).length > 0) {
      props.setStep(3);
    }
    setSkipRender(false)
  }, [props.becomeSeller]);


  const handleSubmit = (event, doc, index) => {
    setActiveIndex(index);
    event.preventDefault();
    setUploadDocumentID(doc.document_id);
    props.dispatch(becomeSellerStart(inputData));
  };

  return (
    <>
      <div className="import-rules-sec">
        <div className="become-a-seller-header-sec">
          <div className="become-a-seller-steps-badge">
            {t("2_4_completed")}
          </div>
        </div>
        <div className="become-a-seller-body-sec">
          <div className="become-a-seller-step-count-sec">
            <div className="become-a-seller-step-count-bg">
              2
            </div>
            <span>{t("account_certification")}</span>
          </div>
          <div className="upload-doc-sec main-wrapper">
            <Container>
              {props.kycDocDetails.loading ? (
                <KycLoader />
              ) :
                props.kycDocDetails.data.documents &&
                  props.kycDocDetails.data.documents.length > 0
                  ? (
                    props.kycDocDetails.data.documents.map((doc, index) => (
                      <Form className="form-box" key={index}>
                        <div className="title-wrap">
                          <h3 className="title">
                            <i class="fas fa-images mr-2"></i>
                            {doc.name}
                          </h3>
                          <p className="desc doc-desc">{doc.description}</p>
                        </div>
                        <Row>
                          <Col lg={6}>
                            <div className="upload-doc-card">
                              <div className="upload-doc-box">
                                <Image
                                  src={
                                    image[doc.document_id] !== undefined
                                      ? image[doc.document_id]
                                      : doc.user_document.document_file !== undefined
                                        ? doc.user_document.document_file
                                        : window.location.origin +
                                        "/images/document-upload.svg"
                                  }
                                  alt="no image"
                                  className="doc-upload-img"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form-content contact-form-action">
                              <Row>
                                {props.kycDocDetails.data.is_document_verified === 0 ?
                                  <Col lg={12}>
                                    <div className="file-upload-wrap">
                                      <input
                                        type="file"
                                        name={doc.document_id}
                                        className="multi file-upload-input with-preview"
                                        accept="image/*"
                                        onChange={(event) =>
                                          handleChangeImage(event, doc)
                                        }
                                        disabled={
                                          props.kycDocDetails.data.is_document_verified === 2 ? true : false
                                        }
                                      />
                                      <span className="file-upload-text">
                                        <i className="fa fa-upload mr-2"></i>
                                        {t("click_image_here")}
                                      </span>
                                    </div>
                                  </Col>
                                  : <Col lg={12}>
                                    <div className="file-upload-wrap">
                                      <input
                                        type="file"
                                        name={doc.document_id}
                                        className="multi file-upload-input with-preview"
                                        accept="image/*"
                                        onChange={(event) =>
                                          handleChangeImage(event, doc)
                                        }
                                        disabled={
                                          props.kycDocDetails.data.is_document_verified === 2
                                        }

                                      />
                                      <span className="file-upload-text">
                                        <i className="fa fa-upload mr-2"></i>
                                        {t("click_image_here")}
                                      </span>
                                    </div>
                                  </Col>
                                }
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <div className="upload-doc-btm-sec">
                          {props.kycDocDetails.data.is_document_verified === 2 ? <span className="theme-btn">{t("verified")}</span> :
                            <Button
                              className="theme-btn mt-3 mb-3"
                              type="submit"
                              onClick={(event) => handleSubmit(event, doc, index)}
                              disabled={!inputData.document_file && activeIndex === index}
                            >
                              {activeIndex === index && props.becomeSeller.loadingButtonContent !== null ? props.becomeSeller.loadingButtonContent : t("send_for_approval")}
                            </Button>
                          }
                        </div>
                      </Form>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
              {props.profile.data.content_creator_step === 2 ?
                <div className="become-a-seller-btn-sec">
                  <Button
                    className="default-blue-btn"
                    onClick={() => props.setStep(3)}
                  >
                    {t("continue")}
                  </Button>
                </div>
                : null
              }
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  kycDocDetails: state.users.kycDocDetails,
  becomeSeller: state.users.becomeSeller,
  profile: state.users.profile,

})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(AccountCertificationIndex));

