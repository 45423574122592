import React from "react";
import {
    Container,
    Image,
    Nav,
    Tab,
    Row,
    Col,
    InputGroup,
    Form,
    Table,
    Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./UploadDocument.css";

const UploadDocumentIndex = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="single-page-sec">
                <Container>
                    <div className="single-page-header">
                        <div className="single-page-title">
                            <Link to="#" onClick={() => navigate(-1)}>
                                <Image src={window.location.origin + "/images/back.svg"} />
                            </Link>
                            <div className="single-page-desc">
                                <h3>Add Documents</h3>
                            </div>
                        </div>
                    </div>
                    <div className="upload-document-box">
                        <h3>UPLOAD DOCUMENTS</h3>
                        <div className="upload-document-item">
                            <Row>
                                <Col md={3}>
                                    <div className="upload-document-card-new">
                                        <div className="upload-document-pending-sec">
                                            <Image src={window.location.origin + "/images/upload-document/pending-icon.svg"} className="upload-document-pending"/>
                                        </div>
                                        <div className="upload-document-img-sec">
                                            <Image src={window.location.origin + "/images/upload-document/upload-icon.svg"} className="upload-document-img"/>
                                        </div>
                                        <div className="upload-document-note-sec">
                                            <h6>Add Your Aadhar Card Here</h6>
                                            <p>png, jpg & Pdf</p>
                                        </div>
                                        <div className="upload-document-name-sec">
                                            <h5>aadhar076436598347.png</h5>
                                            <Image src={window.location.origin + "/images/upload-document/close.svg"} className="upload-close-icon"/>
                                        </div>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">Upload a file</button>
                                            <input type="file" name="myfile" />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="upload-document-card-new">
                                        <div className="upload-document-success-sec">
                                            <Image src={window.location.origin + "/images/upload-document/tick-mark.svg"} className="upload-document-success"/>
                                        </div>
                                        <div className="upload-document-img-sec">
                                            <Image src={window.location.origin + "/images/upload-document/upload-icon.svg"} className="upload-document-img"/>
                                        </div>
                                        <div className="upload-document-note-sec">
                                            <h6>Add Your Aadhar Card Here</h6>
                                            <p>png, jpg & Pdf</p>
                                        </div>
                                        <div className="upload-document-name-sec">
                                            <h5>aadhar076436598347.png</h5>
                                            <Image src={window.location.origin + "/images/upload-document/close.svg"} className="upload-close-icon"/>
                                        </div>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">Upload a file</button>
                                            <input type="file" name="myfile" />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="upload-document-card-new">
                                        <div className="upload-document-img-sec">
                                            <Image src={window.location.origin + "/images/upload-document/upload-icon.svg"} className="upload-document-img"/>
                                        </div>
                                        <div className="upload-document-note-sec">
                                            <h6>Add Your Aadhar Card Here</h6>
                                            <p>png, jpg & Pdf</p>
                                        </div>
                                        <div className="upload-document-name-sec">
                                            <h5>aadhar076436598347.png</h5>
                                            <Image src={window.location.origin + "/images/upload-document/close.svg"} className="upload-close-icon"/>
                                        </div>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">Upload a file</button>
                                            <input type="file" name="myfile" />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="upload-document-card-new">
                                        <div className="upload-document-img-sec">
                                            <Image src={window.location.origin + "/images/upload-document/upload-icon.svg"} className="upload-document-img"/>
                                        </div>
                                        <div className="upload-document-note-sec">
                                            <h6>Add Your Aadhar Card Here</h6>
                                            <p>png, jpg & Pdf</p>
                                        </div>
                                        <div className="upload-document-name-sec">
                                            <h5>aadhar076436598347.png</h5>
                                            <Image src={window.location.origin + "/images/upload-document/close.svg"} className="upload-close-icon"/>
                                        </div>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">Upload a file</button>
                                            <input type="file" name="myfile" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default UploadDocumentIndex;
