import React, { useEffect } from 'react';
import { Image, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import "./Seller.css";
import { connect } from 'react-redux';

const AwaitingForDocumentApprovalIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    if (props.profile.data.is_document_verified === 1) {
      props.setStep(2);
    }
  }

  return (
    <>
      <div className="import-rules-sec">
        <div className="become-a-seller-header-sec">

          <div className="become-a-seller-steps-badge">
            {t("3_4 Completed")}
          </div>
        </div>
        <div className="become-a-seller-body-sec">
          <div className="become-a-seller-step-count-sec">
            <div className="become-a-seller-step-count-bg">
              3
            </div>
            <span>{t("waiting_for_document_approval")}</span>
          </div>
          <div className="awaiting-for-approval-box">
            <div className="awaiting-for-approval-img-sec">
              <Image
                src={window.location.origin + "/images/content-creator/waiting-for-approval-img.svg"}
                className="awaiting-for-approval-img"
              />
            </div>
            <div className="awaiting-for-approval-info">
              <h4>{t("your_request_is_pending_validation")}</h4>
              <p>{t("you_will_be_notified_when_it_is_processed")}</p>
            </div>
          </div>
          <div className="become-a-seller-btn-sec">
            <div className="approve-back">
              <Button className="default-blue-btn"
                onClick={goBack}
              >
                {t("go_back")}
              </Button>
            </div>
            <div className="approve-continue">
              <Button className="default-blue-btn"
                disabled={props.profile.data.is_document_verified === 1}
                onClick={() => props.setStep(4)}>
                {t("continue")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  profile: state.users.profile,

})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(AwaitingForDocumentApprovalIndex));

