import {
    FETCH_WALLET_DETAILS_START,
    FETCH_WALLET_DETAILS_SUCCESS,
    FETCH_WALLET_DETAILS_FAILURE,
    ADD_MONEY_VIA_PAYPAL_START,
    ADD_MONEY_VIA_PAYPAL_SUCCESS,
    ADD_MONEY_VIA_PAYPAL_FAILURE,
    GENERATE_STRIPE_PAYMENT_START,
    GENERATE_STRIPE_PAYMENT_SUCCESS,
    GENERATE_STRIPE_PAYMENT_FAILURE,
    PAYMENT_BY_STRIPE_START,
    PAYMENT_BY_STRIPE_SUCCESS,
    PAYMENT_BY_STRIPE_FAILURE,
    SEND_WITHDRAW_REQUEST_START,
    SEND_WITHDRAW_REQUEST_SUCCESS,
    SEND_WITHDRAW_REQUEST_FAILURE,
    FETCH_WITHDRAWALS_START,
    FETCH_MORE_WITHDRAWALS_START,
    FETCH_WITHDRAWALS_SUCCESS,
    FETCH_WITHDRAWALS_FAILURE,
    CANCEL_WITHDRAW_REQUEST_START,
    CANCEL_WITHDRAW_REQUEST_SUCCESS,
    CANCEL_WITHDRAW_REQUEST_FAILURE,
    FETCH_PAYMENTS_START,
    FETCH_PAYMENTS_SUCCESS,
    FETCH_PAYMENTS_FAILURE,
} from "./ActionConstant";

export function fetchWalletDetailsStart(data) {
    return {
        type: FETCH_WALLET_DETAILS_START,
        data,
    };
}

export function fetchWalletDetailsSuccess(data) {
    return {
        type: FETCH_WALLET_DETAILS_SUCCESS,
        data,
    };
}

export function fetchWalletDetailsFailure(error) {
    return {
        type: FETCH_WALLET_DETAILS_FAILURE,
        error,
    };
}

export function addMoneyViaPaypalStart(data) {
    return {
        type: ADD_MONEY_VIA_PAYPAL_START,
        data,
    };
}

export function addMoneyViaPaypalSuccess(data) {
    return {
        type: ADD_MONEY_VIA_PAYPAL_SUCCESS,
        data,
    };
}

export function addMoneyViaPaypalFailure(error) {
    return {
        type: ADD_MONEY_VIA_PAYPAL_FAILURE,
        error,
    };
}

export function generateStripeStart(data) {
    return {
        type: GENERATE_STRIPE_PAYMENT_START,
        data,
    };
}

export function generateStripeSuccess(data) {
    return {
        type: GENERATE_STRIPE_PAYMENT_SUCCESS,
        data,
    };
}

export function generateStripeFailure(error) {
    return {
        type: GENERATE_STRIPE_PAYMENT_FAILURE,
        error,
    };
}

export function paymentByStripeStart(data) {
    return {
        type: PAYMENT_BY_STRIPE_START,
        data,
    };
}

export function paymentByStripeSuccess(data) {
    return {
        type: PAYMENT_BY_STRIPE_SUCCESS,
        data,
    };
}

export function paymentByStripeFailure(error) {
    return {
        type: PAYMENT_BY_STRIPE_FAILURE,
        error,
    };
}

export function sendWithDrawRequestStart(data) {
    return {
      type: SEND_WITHDRAW_REQUEST_START,
      data,
    };
  }
  
  export function sendWithDrawRequestSuccess(data) {
    return {
      type: SEND_WITHDRAW_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function sendWithDrawRequestFailure(error) {
    return {
      type: SEND_WITHDRAW_REQUEST_FAILURE,
      error,
    };
  }

  export function fetchWithDrawalsStart(data) {
    return {
      type: FETCH_WITHDRAWALS_START,
      data,
    };
  }
  
  export function fetchWithDrawalsSuccess(data) {
    return {
      type: FETCH_WITHDRAWALS_SUCCESS,
      data,
    };
  }
  
  export function fetchWithDrawalsFailure(error) {
    return {
      type: FETCH_WITHDRAWALS_FAILURE,
      error,
    };
  }

  export function fetchMoreWithdrawalsStart(data) {
    return {
        type: FETCH_MORE_WITHDRAWALS_START,
        data,
    };
}
  
  export function cancelWithDrawRequestStart(data) {
    return {
      type: CANCEL_WITHDRAW_REQUEST_START,
      data,
    };
  }
  
  export function cancelWithDrawRequestSuccess(data) {
    return {
      type: CANCEL_WITHDRAW_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function cancelWithDrawRequestFailure(error) {
    return {
      type: CANCEL_WITHDRAW_REQUEST_FAILURE,
      error,
    };
  }

  export function fetchPaymentsStart(data) {
    return {
      type: FETCH_PAYMENTS_START,
      data,
    };
  }
  
  export function fetchPaymentsSuccess(data) {
    return {
      type: FETCH_PAYMENTS_SUCCESS,
      data,
    };
  }
  
  export function fetchPaymentsFailure(error) {
    return {
      type: FETCH_PAYMENTS_FAILURE,
      error,
    };
  }
  