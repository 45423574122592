import {
    FETCH_STATIC_PAGE_START,
    FETCH_STATIC_PAGE_SUCCESS,
    FETCH_STATIC_PAGE_FAILURE,
  } from "./ActionConstant";
  
  export function fetchStaticPageStart(data) {
    return {
      type: FETCH_STATIC_PAGE_START,
      data,
    };
  }
  
  export function fetchStaticPageSuccess(data) {
    return {
      type: FETCH_STATIC_PAGE_SUCCESS,
      data,
    };
  }
  
  export function fetchStaticPageFailure(error) {
    return {
      type: FETCH_STATIC_PAGE_FAILURE,
      error,
    };
  }
  