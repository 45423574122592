import {
    FETCH_ALL_TRANSACTION_START,
    FETCH_ALL_TRANSACTION_SUCCESS,
    FETCH_ALL_TRANSACTION_FAILURE,
    FETCH_MORE_LIVE_VIDEOS_HISTORY_START
} from "../actions/ActionConstant";

const initialState = {
    allTransaction: {
        data: {},
        loading: true,
        error: false,
    },
};

const TransactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_TRANSACTION_START:
            return {
                ...state,
                allTransaction: {
                    data: {
                        history: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                },
            };
        case FETCH_ALL_TRANSACTION_SUCCESS:
            return {
                ...state,
                allTransaction: {
                    data: {
                        history: [...state.allTransaction.data.history, ...action.data.history],
                        total: action.data.total,
                      },
                    loading: false,
                    error: false,
                },
            };
        case FETCH_ALL_TRANSACTION_FAILURE:
            return {
                ...state,
                allTransaction: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };

        case FETCH_MORE_LIVE_VIDEOS_HISTORY_START:
            return state;

        default:
            return state;
    }
};

export default TransactionReducer;
