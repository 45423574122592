import React, { useCallback } from 'react';
import { Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Seller.css";
import { withTranslation, useTranslation } from "react-multi-lang";

const UpdateBillingInformationIndex = (props) => {
  const t = useTranslation();

  return (
    <>
      <div className="import-rules-sec">
        <div className="become-a-seller-header-sec">
          <div className="become-a-seller-steps-badge">
            {t("4_4 Completed")}
          </div>
        </div>
        <div className="become-a-seller-body-sec">
          <div className="become-a-seller-step-count-sec">
            <div className="become-a-seller-step-count-bg">
              4
            </div>
            <span>{t("now_you_are_a_seller")}</span>
          </div>
          <div className="seller-update-billing-info-box">
            <div className="seller-update-billing-img-sec">
              <Image
                src={window.location.origin + "/images/content-creator/update-billing-information-img.svg"}
                className="seller-update-billing-img"
              />
            </div>
            <div className="seller-update-billing-info">
              <h4>{t("congratulations_you_are_now_a_seller")}</h4>
              <p>{t("update_billing_information_para")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBillingInformationIndex;
