import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Verification.css";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { registerVerifyStart, registerVerifyResendStart } from "../../store/actions/UserAction";


const VerificationIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    } else {
      let userId = localStorage.getItem("userId");
      let accessToken = localStorage.getItem("accessToken");
      let userLoginStatus = localStorage.getItem("userLoginStatus");
      let authentication = userId && accessToken && userLoginStatus == "true" ? true : false;
      if (authentication) {
        navigate("/");
      } else {
        navigate("/register");
      }
    }
  }, []);

  useEffect(() => {
    if (!skipRender &&
      !props.registerVerify.loading &&
      Object.keys(props.registerVerify.data).length > 0) {
      navigate("/")
    }
    setSkipRender(false);

  }, [props.registerVerify]);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const submitVerfication = (event) => {
    event.preventDefault();
    if (otp && otp.length === 6) {
      props.dispatch(
        registerVerifyStart({
          email: email,
          verification_code: otp,
        }));
    }
  };
  const resendVerfication = () => {
    props.dispatch(registerVerifyResendStart({
      email: email,
    }));
  };

  return (
    <>
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={5}>
              <Link to="/login">
                <div className="verification-logo-sec">
                  <Image
                    className="verification-logo"
                    src={
                      window.location.origin + "/images/logo-black.png"
                    }
                  />
                </div>
              </Link>
              <div className="verification-box">
                <h4>
                  {t("verify_you_account_para")}
                </h4>
                <p>{t("we_emailed_otp_to")} {email}
                </p>
                <div className="verification-item">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                  />
                </div>
                <div className="verification-btn-sec">
                  <Button
                    className="default-btn"
                    onClick={submitVerfication}
                    disabled={otp.length < 6 && props.registerVerify.buttonDisable}
                  >
                    {props.registerVerify.loadingButtonContent ?
                      props.registerVerify.loadingButtonContent :
                      t("validate")
                    }
                  </Button>
                  <Button
                    className="default-outline-btn"
                    onClick={resendVerfication}
                    disabled={props.registerVerifyResend.buttonDisable}
                  >
                    {props.registerVerifyResend.loadingButtonContent != null ?
                      props.registerVerifyResend.loadingButtonContent :
                      t("resend")
                    }
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  registerVerify: state.users.registerVerify,
  registerVerifyResend: state.users.registerVerifyResend,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(VerificationIndex));
