import React, { useState, useEffect } from "react";
import { Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Seller.css";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { addBankAccountStart } from "../../store/actions/BankAccountAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";
import { becomeSellerStart } from "../../store/actions/UserAction";

const SellerAddBankIndex = (props) => {
  const options = [
    { value: "savings", label: "Savings" },
    { value: "current account", label: "Current Account" },
  ];
  const navigate = useNavigate();
  const t = useTranslation();
  const [agree, setAgree] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !props.becomeSeller.loading && Object.keys(props.becomeSeller.data).length > 0) {
      props.setStep(6);
    }
    setSkipRender(false)
  }, [props.becomeSeller]);

  const handleSubmit = (values) => {
    props.dispatch(becomeSellerStart({
      ...values,
      step: 3,
    }));
  }

  const addbillingSchema = Yup.object().shape({
    route_number: Yup.string().required(t("route_number_is_required")),
    account_number: Yup.string().required(t("account_number_is_required")),
    first_name: Yup.string().required(t("first_name_is_required")),
    last_name: Yup.string().required(t("last_name_is_required")),
    bank_type: Yup.string().required(t("bank_type_is_required"))
  })

  const customStyles = {
    ///.....
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(57,57,57,.07)",
      borderColor: "#e7e7e7",
      boxShadow: "none",
      height: "50px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "var(----active-color)",
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
    ///.....
  };
  return (
    <>
      <div className="import-rules-sec">
        <div className="become-a-seller-header-sec">
          <div className="become-a-seller-steps-badge">4/4 Completed</div>
        </div>
        <div className="become-a-seller-body-sec">
          <div className="become-a-seller-step-count-sec">
            <div className="become-a-seller-step-count-bg">4</div>
            <span>{t("seller_add_bank")}</span>
          </div>
          <div className="seller-bank-box">
            <p>
              {t("seller_add_bank_para")}
            </p>
            <Formik initialValues={{
              route_number: "",
              account_number: "",
              first_name: "",
              last_name: "",
              bank_type: "savings",
              bank_name: "",
            }}
              validationSchema={addbillingSchema}
              onSubmit={(values => handleSubmit(values))}>
              <FORM className="seller-bank-form">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("routing_numer")} (*)</Form.Label>
                  <Field
                    type="number"
                    name="route_number"
                    placeholder={t("routing_numer")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="route_number"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("account_number")} (*)</Form.Label>
                  <Field
                    type="number"
                    name="account_number"
                    placeholder={t("account_number")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="account_number"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("type_of_bank")}(*)</Form.Label>
                  <Field
                    as="select"
                    className="form-control"
                    name="bank_type"
                    placeholder={t("type_of_bank")}>
                    <option value="savings">{t("savings")}</option>
                    <option value="checking">{t("checking")}</option>
                  </Field>
                  <ErrorMessage
                    name="bank_type"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("first_name")} (*)</Form.Label>
                  <Field
                    type="text"
                    name="first_name"
                    placeholder={t("first_name")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="first_name"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("last_name")}: (*)</Form.Label>
                  <Field
                    type="text"
                    name="last_name"
                    placeholder={t("last_name")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="last_name"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("bank_name")}</Form.Label>
                  <Field
                    type="text"
                    name="bank_name"
                    placeholder={t("bank_name")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bank_name"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label={<div>
                      <span> {t("i_agree_to")} </span>
                      <Link to="/page/terms" target='_blank'> {t("terms_conditions")}</Link>
                    </div>
                    }
                    required
                    name="acceptTerms"
                    checked={agree}
                    onChange={() => (setAgree(!agree))}
                  />
                </Form.Group>
                <div className="submit-btn">
                  <Button type="submit"
                    className="default-btn"
                    disabled={props.becomeSeller.buttonDisable ||
                      !agree
                    }
                  >
                    {props.becomeSeller.loadingButtonContent != null ?
                      props.becomeSeller.loadingButtonContent :
                      t("submit")}
                  </Button>
                </div>
              </FORM>
            </Formik>
          </div>
          {/* <div className="become-a-seller-btn-sec">
            <Button
              className="default-blue-btn"
              onClick={() => props.setStep(6)}
            >
              {t("continue")}
            </Button>
          </div> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  becomeSeller: state.users.becomeSeller,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(SellerAddBankIndex));


